import React, { useEffect, useState, Fragment, useContext } from "react";
import { eventHighlights } from "../data/EventDetails";
import Logo from "../components/Logo";
import { Link } from 'gatsby'
import Layout from "../components/layout";
import Navbar from "../components/Navbar";
import config from "../config"
import RegistrationForm from "../components/RegistrationForm";
import { hasEventEnded, returnDateToUseForCECRequests } from '../helpers/dates'
import SEO from "../components/SEO";
const dayjs = require("dayjs");

function RegistrationPage(props) {

  const [futureVirtualCBE, setFutureVirtualCBE] = useState(null);

  useEffect(() => {  
     
    fetch(config.API.CEC.URL + '/cec-classmgmt/event/future-virtual-cbe?startDate=' + returnDateToUseForCECRequests())
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {  
        setFutureVirtualCBE(resultData)
      }) 
    }, [])


  return (
    <Layout location={props.location} showHeader={true}>
      <SEO title="Register for CBEE"/>
       <div className="max-w-7xl mx-auto px-4 md:px-6" id="top">
      <div className="py-4 flex justify-between items-center">
      <div className="w-80">
                <Link
                  to={"/"}
                  className="block focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                >
                  <Logo fill="#00659e" includeTitle={true} />
                </Link>
              </div>
            
       
      </div>    </div>
      <Navbar location={props.location} hasEventEnded={hasEventEnded(futureVirtualCBE && futureVirtualCBE.eventDayDetails[1] && futureVirtualCBE && futureVirtualCBE.eventDayDetails[1].eventDayDtlEndDttm)} />
      <div className="overflow-hidden">
        <div className="h-auto bg-cover relative bg-brand-900">
       

          <div
            className="relative bg-gray-100"

          >
            <div
              className={`max-w-7xl py-4 carousel-content-container flex flex-wrap justify-between relative mx-auto px-4 md:px-6 pb-64`}
            >
              {/* <div className="w-full py-2 md:py-4 md:pr-8 lg:pr-32 relative ">
                <div className="max-w-5xl">
                  <span className="text-left block font-semibold my-2 text-lg text-gray-600 tracking-wide service-heading-tag">
                    {eventDetails.eyebrow}
                  </span>
                  <h1 className="text-left text-black relative mb-2 text-2xl md:text-4xl font-bold leading-8 md:leading-10">
                    Register for {config.APP.APP_NAME}
                  </h1>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className={`max-w-7xl pb-8 -mt-48  mx-auto md:px-6 relative`}>
        <div className={`bg-white md:rounded-lg md:shadow-xl`}>
          <div className="flex flex-wrap">
            <div className="w-full md:w-2/3 sm:w-2/3 px-6 lg:px-12 py-6 md:py-12">
             
              {/* <p className="text-gray-600 pb-2">
                Complete the following information to register.
              </p> */}

        
              <RegistrationForm/>
            </div>
            <div className="md:block w-full sm:w-1/3 md:w-1/3 p-10 bg-gray-50 rounded-r-none md:rounded-b-lg md:rounded-r-lg">

              <ul className="flex flex-wrap">
                {eventHighlights.map((hightlight) => (
                  <li
                    key={hightlight.id}
                    className="w-full text-center p-6 py-10"
                  >
                    <div

                      className="bg-white border-2 border-brand-500 flex h-16 items-center justify-center mb-3 mx-auto rounded-full w-16"
                    >
                      <hightlight.icon
                        className="h-6 w-6 mx-auto"
                        style={{ color: "#006BA6" }}
                        aria-hidden="true"
                      />
                    </div>
                    <span className="text-base font-semibold mb-2 block">
                      {hightlight.text}
                    </span>

                    <p className="text-gray-500">{hightlight.description}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default RegistrationPage;
